import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/layout"
import ContentPage from "../../layouts/contentPage"

import Seo from "../../components/seo"

import ProductHero from "../../components/Product/productHero"
import H1 from "../../components/h1"
import ButtonColor from "../../components/buttonColor"
import ProductInfo from "../../components/Product/productInfo"
import ProductPackshot from "../../components/Product/productPackshot"
import ProductPackshotBubbles from "../../components/Product/productPackshotBubbles"
import BubbleBg from "../../components/Product/bubbleBg"
import ProductSidebar from "../../components/Product/productSidebar"
import More from "../../components/more"
import Tabs from "../../components/Tabs/tabs"
import ProductDescription from "../../components/Product/productDescription"
import Energyze from "../../images/media/products/energyze/energyze.svg";
import Ingredients from "../../components/Product/ingredients"
import Ingredient from "../../components/Product/ingredient"
import Effectivness from "../../components/Product/effectivness"
import EffectivnessCard from "../../components/Product/effectivnessCard"
import ActiveIngredients from "../../components/Product/activeIngredients"
import ActiveIngredient from "../../components/Product/activeIngredient"
import DescriptionPackshot from "../../components/Product/descriptionPackshot"
import Container from "../../components/Product/container"






const Product = () => (
    <Layout>
      <Seo title="Rewitalizujące serum pod oczy - Linia Energyze" />
        <ProductHero>

            {/* Info */}
            <div data-column>
                <ProductInfo color="lime">
                    <H1>Rewitalizujące serum pod oczy</H1>
                    <p>Rozświetlenie i regeneracja</p>
                    <ProductPackshot mobile>
                        <StaticImage
                        src="../../images/media/products/energyze/products/rewitalizujace-serum.png"
                        loading="eager"
                        width={580}
                        quality={95}
                        placeholder="none"
                        formats={["auto", "webp", "avif"]}
                        alt="Regeneracyjne serum"
                        />
                        <ProductPackshotBubbles/>
                </ProductPackshot>

                    <ul>
                        <li>Wygładza i rozświetla. </li>
                        <li>Zmniejsza cienie i opuchnięcia pod oczami. </li>
                        <li>Dotlenia i usprawnia funkcjonowanie komórek. </li>
                        <li>Wspiera mikrobiom i odbudowuje barierę ochronną</li>
                    </ul>
                    <Container>
                    <ButtonColor as="a" target="_blank" href="https://www.hebe.pl/oxygenetic-rewitalizujace-serum-pod-oczy-15-ml-000000000000407508.html" color="lime">Kup w Hebe</ButtonColor>
                    <ButtonColor as="a" target="_blank" href="https://www.zikodermo.pl/oxygenetic-energyze-rewitalizujace-serum-pod-oczy-15-ml.html" color="lime">Kup w Ziko Dermo</ButtonColor>
                    <ButtonColor as="a" target="_blank" href="https://gemini.pl/oxygenetic-energyze-vitamin-c-rewitalizujace-serum-pod-oczy-15-ml-0107788" color="lime">Kup w Gemini</ButtonColor>
                    </Container>
                </ProductInfo>
            </div>

            {/* Packshot */}
            <div data-column>
                <ProductPackshot>
                        <StaticImage
                        src="../../images/media/products/energyze/products/rewitalizujace-serum.png"
                        loading="eager"
                        width={580}
                        quality={95}
                        placeholder="none"
                        formats={["auto", "webp", "avif"]}
                        alt="rewitalizujace serum"
                        />
                        <ProductPackshotBubbles/>
                </ProductPackshot>
                <More url="#opis-produktu" color="lime" text="więcej"/>
            </div>

            {/* Category Products */}
            <div data-column>
            <ProductSidebar>
                <Link to="/produkty/regeneracyjne-serum">
                        <StaticImage
                             src="../../images/media/products/energyze/products/regeneracyjne-serum.png"
                            loading="eager"
                            // width={142}
                            quality={95}
                            placeholder="blurred"
                            formats={["auto", "webp", "avif"]}
                            alt="normalizujace-serum"
                        />
                    </Link>
                    <Link to="/produkty/dotleniajacy-sorbet">
                        <StaticImage
                             src="../../images/media/products/energyze/products/nawilzajacy-sorbet.png"
                            loading="eager"
                            // width={142}
                            quality={95}
                            placeholder="blurred"
                            formats={["auto", "webp", "avif"]}
                            alt="nawilzajace-serum"
                        />
                    </Link>
                    <Link to="/produkty/energetyzujace-serum">
                        <StaticImage
                            src="../../images/media/products/energyze/products/energetyzujace-serum.png"
                            loading="eager"
                            // width={142}
                            quality={95}
                            placeholder="blurred"
                            formats={["auto", "webp", "avif"]}
                            alt="energetyzujace serum"
                        />
                    </Link>
                    <Link to="/produkty/normalizujace-serum">
                        <StaticImage
                            src="../../images/media/products/energyze/products/normalizujace-serum.png"
                            loading="eager"
                            // width={142}
                            quality={95}
                            placeholder="blurred"
                            formats={["auto", "webp", "avif"]}
                            alt="normalizujace serum"
                        />
                    </Link>
                </ProductSidebar>
            </div>
            {/* <BubbleBg/> */}
        </ProductHero> 
        <section id="opis-produktu">

            {/* TABS */}
            <Tabs color="lime">

                {/* OPIS */}
                <div label="Opis" className="tab-list-active">
                <ProductDescription color="lime">
                    <div className="descriptionRow">
                        <div className="column">
                            <div className="text--left">

                                <p>
                                    <strong>Serum natychmiast dotlenia delikatną skórę okolic oczu stymulując procesy produkcji nowych komórek oraz wzmacnia jej naturalną warstwę ochronną.</strong>
                                </p>
                                <p>
                                    Rewitalizuje i poprawia elastyczność, nadaje promienny wygląd cery, utrzymuje optymalny poziom nawilżenia i działa przeciwzmarszczkowo.
                                </p>
                                <p>
                                    Dodatkowo serum zmniejsza widoczność cieni i opuchnięć pod oczami. Efekt to głęboko nawilżona, pełna witalności i blasku skóra wokół oczu, która nadaje świeże spojrzenie.
                                </p>
                            </div>
                        </div>
                        <div className="column">
                            <DescriptionPackshot>
                                <StaticImage
                                src="../../images/media/products/energyze/products/rewitalizujace-serum_single.png"
                                loading="lazy"
                                width={534}
                                quality={95}
                                placeholder="none"
                                formats={["auto", "webp", "avif"]}
                                alt="rewitalizujace-serum"
                             />
                            </DescriptionPackshot>
                        </div>
                        <div className="column">
                            <div className="text--right">
                                <p><strong>Sposób użycia:</strong></p>
                                <p>Stosować codziennie rano i wieczorem na oczyszczoną skórę okolic oczu. Tylko do użytku zewnętrznego. Unikać bezpośredniego kontaktu z oczami.</p>
                                <br/>
                                <p><strong>Pojemność:</strong></p>
                                <p>15ml</p>
                                <Energyze/>
                            </div>
                        </div>
                    </div>        
                </ProductDescription>
                </div>

                {/* BAZA */}
                <div label="Baza">
                <Ingredients>
                    <Ingredient name="Witamina C" desc="To silny antyoksydant, który neutralizuje wolne rodniki uszkadzające komórki skóry. Spowalnia procesy starzenia i chroni przed czynnikami zewnętrznymi. Dodatkowo rozjaśnia przebarwienia, a także przyspiesza odnowę komórkową skóry poprzez zwiększenie produkcji kolagenu i elastyny." />
                    <Ingredient name="Kompleks dotleniających minerałów" desc="Składnik, który wiąże cząsteczki tlenu zwiększając ich dostępność w skórze oraz stymuluje metabolizm komórkowy. Dzięki czemu komórki skóry otrzymują niezbędną energię, zaczynają prawidłowo funkcjonować i regenerować się. W efekcie znacznie poprawia się wygląd skóry, staje się ona zdrowa i promienna." />
                    <Ingredient name="Synbiotyk" desc="To kompleks wzajemnie uzupełniającego się probiotyku i prebiotyku, który wzmacnia naturalną warstwę ochronną skóry - mikrobiom, chroniąc przed niekorzystnymi czynnikami zewnętrznymi. Dodatkowo wykazuje działanie nawilżające, odżywcze i wygładzające." />
                </Ingredients>
                </div>

                {/* SKŁADNIKI AKTYWNE */}
                <div label="Składniki aktywne">
                <ActiveIngredients>
                    <ActiveIngredient name="Stabilny polifenol" desc="To składnik pozyskiwany z eskuliny, który pobudza mikrocyrkulację krwi, a także wzmacnia naczynia krwionośne." />
                </ActiveIngredients>
                </div>

                {/* SKUTECZNOŚĆ */}
                <div label="Skuteczność">
                <Effectivness>
                    <EffectivnessCard color="lime" percentage="93" desc="badanych potwierdza, że produkt ogranicza powstawanie zmarszczek wokół oczu"/>
                    <EffectivnessCard color="lime" percentage="100" desc="badanych potwierdza, że regularne stosowanie produktu zmniejsza cienie pod oczami"/>
                    <EffectivnessCard color="lime" percentage="93" desc="badanych potwierdza, że produkt zapobiega utracie elastyczności i jędrności skóry"/>
                </Effectivness>
                </div>
            </Tabs>
        </section>
    </Layout>
)

export default Product
